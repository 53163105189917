import { render, staticRenderFns } from "./PrivacyView.vue?vue&type=template&id=4508d896&scoped=true&"
import script from "./PrivacyView.vue?vue&type=script&lang=js&"
export * from "./PrivacyView.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyView.vue?vue&type=style&index=0&id=4508d896&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4508d896",
  null
  
)

export default component.exports