<template>
  <div>
    <section class="banner" v-if="primaryBanner === 'active' ">
      <div class="container text-center">
        <div class="content-wrapper">
          <img src="../assets/images/banner-usd-icon.png" class="banner-ethereum" alt="">
          <img src="../assets/images/banner-eurst-icon.png" class="banner-flow" alt="">
          <img src="../assets/images/banner-wallex-icon.png" class="banner-wallex" alt="">
          <img src="../assets/images/banner-dai-icon.png" class="banner-solana" alt="">
          <img src="../assets/images/banner-dai-icon.png" class="banner-solana" alt="">
          <img src="../assets/images/banner-tether-icon.png" class="banner-tether" alt="">
          <img src="../assets/images/banner-eurst-icon2.png" class="banner-eurst" alt="">

          <strong v-html="primaryBannerTag"></strong>
          <h1 v-html="primaryBannerTitle"></h1>
          <p class="p" v-html="primaryBannerDescription"></p>
          <RouterLink :to="buttonLink" class="btn-style" v-if="buttonLink !== ''">Get Started</RouterLink>
          <img src="../assets/images/banner-eurst.png" class="banner-center-img" alt="">
        </div>
      </div>
    </section>
  </div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'Banner',
  props: ['primaryBanner', 'secondaryBanner', 'atmBanner', 'primaryBannerTag', 'primaryBannerTitle', 'primaryBannerDescription', 'buttonLink', 'secondaryBannerImg',
          'playStore', 'bannerForm', 'id'],
  components: {
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
