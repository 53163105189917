<template>
  <div class="news w-100 overflow-hidden">
    <Header />
    <div class="container-fluid sec1">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2 id="featuredtitle1">
              
            </h2>
            <p>
              There are no pre-minted tokens, EURST is only minted for
              circulation on the Ethereum Blockchain upon demand, by verified
              eurst.io users. Anyone registered and verified on EURST can mint
              via bank transfer or directly with card. Funds are directly
              allocated to the escrow collateral account, and held there for the
              benefit of the EURST holders.
            </p>
            <a href="#" id="featuredReadMore" class="d-md-block d-none">
              Read More <img src="../assets/images/arrow-right-blue.png" alt="" />
            </a>
          </div>
          <div class="col-md-6">
            <img class="d-md-block d-none" id="featuredimg1" src="" alt="" />
            <img class="d-block d-md-none" id="featuredimg11" src="" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sec2">
      <div class="container">
        <div class="py-5 d-flex justify-content-between align-items-center">
          <h3>Company Updates</h3>
          <div class="search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div class="con">
          <div id="articlesNew"></div>
          <div class="list">
            <div class="item" v-for="(item, index) in itemsShow" :key="index">
              <img :src="item.imgsrc" alt="" />
              <p>{{ item.date }}</p>
              <h3>{{ item.title }}</h3>
              <a :href="item.link">Read More <img src="../assets/images/arrow-right-white.png" alt="" /></a>
            </div>
          </div>
          <div class="pagination">
            <button class="prev" @click="prevSlide()">
              <i class="fa-solid fa-arrow-left-long"></i>
            </button>
            <span v-for="i in loopArray" :class="i == currslide ? 'active' : ''" @click="setSlide(i)" :key="i">{{ i
            }}</span>
            <button class="next" @click="nextSlide()">
              <i class="fa-solid fa-arrow-right-long"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
<!--    <newsfeed />-->
    <Footer  />
  </div>
</template>

<script>
/* eslint-disable */
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
// import newsfeed from "../components/newsfeed.vue";
import { onMounted, ref } from "vue";

export default {
  components: {
    Header,
    Footer,
    // newsfeed,
  },
 /* setup() {
    const itemsShow = ref([]);
    let slideCount = 0;
    const currslide = ref(1);
    const loopArray = ref([]);

    const items = [
      {
        imgsrc: require("@/assets/buyusrt.png"),
        date: "February 10, 2022",
        title: "How To Buy EURST?",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurstaibt.png"),
        date: "February 10, 2022",
        title: "EURST at AIBC Summit Malta Recap",
        link: "",
      },
      {
        imgsrc: require("@/assets/fintech.png"),
        date: "February 10, 2022",
        title:
          "FinTech Talks with Simone Mazzuca, CEO of EURST and Wallex Custody",
        link: "",
      },
      {
        imgsrc: require("@/assets/icoama.png"),
        date: "February 10, 2022",
        title: "Recap: ICO Speaks AMA with EURST",
        link: "",
      },
      {
        imgsrc: require("@/assets/eursttrans.png"),
        date: "February 10, 2022",
        title: "How EURST Ensures Transparency",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurststable.png"),
        date: "February 10, 2022",
        title: "How EURST Stablecoins are Addressing Market Volatility",
        link: "",
      },
      {
        imgsrc: require("@/assets/eursttrans.png"),
        date: "February 10, 2022",
        title: "How EURST Ensures Transparency",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurststable.png"),
        date: "February 10, 2022",
        title: "How EURST Stablecoins are Addressing Market Volatility",
        link: "",
      },
      {
        imgsrc: require("@/assets/eursttrans.png"),
        date: "February 10, 2022",
        title: "How EURST Ensures Transparency",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurststable.png"),
        date: "February 10, 2022",
        title: "How EURST Stablecoins are Addressing Market Volatility",
        link: "",
      },
      {
        imgsrc: require("@/assets/eursttrans.png"),
        date: "February 10, 2022",
        title: "How EURST Ensures Transparency",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurststable.png"),
        date: "February 10, 2022",
        title: "How EURST Stablecoins are Addressing Market Volatility",
        link: "",
      },
      {
        imgsrc: require("@/assets/eursttrans.png"),
        date: "February 10, 2022",
        title: "How EURST Ensures Transparency",
        link: "",
      },
      {
        imgsrc: require("@/assets/eurststable.png"),
        date: "February 10, 2022",
        title: "How EURST Stablecoins are Addressing Market Volatility",
        link: "",
      },
    ];

    const setSlide = (i) => {
      const max = i * 6;
      const min = max - 6;
      itemsShow.value = [...items.slice(min, max)];
      currslide.value = i;
    };

    const prevSlide = () => {
      if (currslide.value > 1) {
        currslide.value -= 1;
        setSlide(currslide.value);
      }
    };

    const nextSlide = () => {
      if (currslide.value < slideCount) {
        currslide.value += 1;
        setSlide(currslide.value);
      }
    };

    onMounted(() => {
      itemsShow.value = [...items.slice(0, 6)];
      slideCount =
        items.length % 6 > 0 ? parseInt(items.length / 6) + 1 : items.length;
      loopArray.value = [...Array(slideCount + 1).keys()].slice(1);
    });

    return {
      itemsShow,
      loopArray,
      currslide,
      setSlide,
      prevSlide,
      nextSlide,
    };
  },*/
};
</script>

<style scoped>
.sec1 {
  padding-top: 5rem;
}

.sec1 .row {
  min-height: 70vh;
  align-items: center;
  justify-content: flex-start;
}

.sec1 h2 {
  color: #1b2c42;
  font-weight: bold;
  font-size: 3rem;
}

.sec1 p {
  color: #616c7c;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: justify;
  margin: 1.5em 0 2em;
  max-width: 500px;
}

.sec1 p.active {
  -webkit-line-clamp: unset;
}

.sec1 a {
  width: 170px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  text-decoration: none;
  border: none;
  color: white;
  background: #2058e4;
  padding: 0.5em 1.5em;
  font-size: 0.9rem;
  border-radius: 2rem;
}

.sec1 .col-md-6:last-child img {
  height: 60vh;
  margin: auto;
}

.sec2 {
  padding: 3rem 0;
}

.sec2 h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #1b2c42;
}

.sec2 .search {
  position: relative;
  max-width: 200px;
}

.sec2 .search input {
  border: none;
  border-radius: 1.5em;
  background: #f5f6f7;
  padding: 0.5em 1em 0.5em 3em;
  width: 100%;
}

.sec2 .search i {
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
  color: #616c7c;
}

.sec2 .list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.sec2 .item {
  display: flex;
  flex-direction: column;
  padding: 5px 5px 2rem;
  background: #f5f6f7;
  border-radius: 1rem;
  min-height: 400px;
  width: calc(50% - 0.5rem);
  margin: 0 0 1rem;
  aspect-ratio: 1.4;
}

.sec2 .item:nth-child(2n-1) {
  margin-right: 1rem;
}

.sec2 .item img {
  width: 100%;
}

.sec2 .item p {
  padding: 0 1rem;
  font-size: 0.8rem;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #616c7c;
}

.sec2 .item h3 {
  color: #1b2c42;
  font-size: 1.5rem;
  padding: 0 1rem;
}

.sec2 .item a {
  margin: 0 1rem;
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sec2 .item a img {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5em;
}

.sec2 .pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.sec2 .pagination span {
  height: 1.75rem;
  width: 1.75rem;
  font-size: 0.9rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background: #e6eef8;
  color: #2058e4;
  margin: 0.25em;
  cursor: pointer;
}

.sec2 .pagination span.active {
  color: #e6eef8;
  background-color: #2058e4;
}

.sec2 .pagination .next,
.sec2 .pagination .prev {
  background: #222;
  padding: 0.25em 1em;
  color: white;
  border: none;
  border-radius: 2rem;
  margin: 0 0.25em;
}

@media screen and (max-width: 769px) {
  .sec1 {
    padding: 7rem 0 0rem;
  }

  .sec1 h2 {
    font-size: 2rem;
  }

  .sec1 img {
    width: 100%;
    height: auto !important;
    border-radius: 1rem;
  }

  .sec2{
    padding-block: 0;
  }

  .sec2 .d-flex {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .sec2 h3{
    font-size: 1.8rem;
  }

  .sec2 .search{
    margin-top: 1rem;
    width: 100%;
    max-width: 400px;
  }

  .sec2 .item {
    width: 100%;
    margin: 1rem 0;
  }

  .sec2 .item:nth-child(2n-1) {
    margin-right: 0;
  }
}
</style>