<template>
  <footer id="footer">
    <div class="container">
      <div class="row newsletter">
        <div class="col-md-6 align-self-center">
          <div class="logo">
            <RouterLink to="/"><img src="../assets/images/logowhite.svg" alt=""></RouterLink>
          </div>
          <div class="form">
            <form style="margin-left: 0;">
              <div class="form-group">
                <input type="text" id="footermail" placeholder="Your email" class="form-control">
                <button type="button" onclick="saveMail('footermail')" class="btn-style primary-arrow">Get started</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3 align-self-center">
          <strong>Follow Us</strong>
          <ul class="footer-social">
            <li><a href="https://www.facebook.com/profile.php?id=100064229225901&eav=AfbyykjPthVVCdCyVnsk1LVITVuu2rManysuZqja-09VZj4rrL8XEQyqs2MW9OsELTM&paipv=0" target="_blank"><img src="../assets/images/icons/footer-fb-icon.svg" alt=""></a></li>
            <li><a href="https://twitter.com/eurst_io" target="_blank"><img src="../assets/images/icons/footer-twitter-icon.svg" alt=""></a></li>
            <li><a href="https://www.linkedin.com/company/eurst/" target="_blank"><img src="../assets/images/icons/footer-linkedin-icon.svg" alt=""></a></li>
            <li><a href="https://www.instagram.com/eurst.io/" target="_blank"><img src="../assets/images/icons/footer-insta-icon.svg" alt=""></a></li>
            <li><a href="https://t.me/s/eurstablecoin" target="_blank"><img src="../assets/images/icons/footer-telegram-icon.svg" alt=""></a></li>
            <li><a href="https://medium.com/@EURST_Stablecoin" target="_blank"><img src="../assets/images/icons/footer-medium-icon.svg" alt=""></a></li>
          </ul>
        </div>
      </div>
      <div class="row footer-links">
        <div class="col-sm-4 col-lg-3 col-6">
          <strong class="stablecoins">STABLECOINS</strong>
          <ul class="stablecoins">
            <li><RouterLink onclick="goStable();" to="/stablecoins#eurst">EURST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#gbpst">GBPST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#usdst">USDST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#hdkst">HKDST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#aedst">AEDST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#audst">AUDST</RouterLink></li>

            <li><RouterLink onclick="goStable();" to="/stablecoins#bgnst">BGNST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#brzst">BRZST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#cadst">CADST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#jpyst">JPYST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#mxnst">MXNST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#ronst">RONST</RouterLink></li>

            <li><RouterLink onclick="goStable();" to="/stablecoins#rsst">RSST</RouterLink></li>
            <li><RouterLink onclick="goStable();" to="/stablecoins#zarst">ZARST</RouterLink></li>
          </ul>
        </div>
        <div class="col-sm-4 col-lg-3 offset-xl-1 col-6" >
          <strong>ABOUT US</strong>
          <ul class="coming-soon">
            <li><RouterLink to="/contact">Contact Us</RouterLink></li>
            <li><RouterLink to="/news">News</RouterLink></li>
            <li><a href="/uploads/eurst_media_kit.pdf">Media Kit</a></li>
            <li><a href="https://k9654f4dt2i.typeform.com/to/KpEIF4D8?typeform-source=eurst.io">Partenership</a></li>
            <li><a href="https://k9654f4dt2i.typeform.com/to/KpEIF4D8?typeform-source=eurst.io">Institutional Inquiry</a></li>
          </ul>
        </div>
        <div class="col-sm-4 col-lg-3 col-6">
          <strong>Terms</strong>
          <ul>
            <li><RouterLink to="/terms">Terms of Use</RouterLink></li>
            <li><a href="https://real-time-attest.trustexplorer.io/wallex">View Attestation</a></li>
            <li><RouterLink to="/privacy">Privacy Policy</RouterLink></li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <p>© 2022 Wallex. All rights reserved.</p>
        <p>DISCLAIMER: Wallex Trust (“EURST”) is a blockchain and financial technology service provider registered in District of Columbia (File No. T00006482541). EURST provides technology and services to Prime Trust LLC (“PT”), a Nevada-chartered trust company (License No. TR10035). Blockchain-connected products/services offered by EURST are built on top of PT’s infrastructure through API integrations, leveraging their traditional financial services including (but not limited to) traditional asset custody, payments, escrow, trustee and compliance services. EURST is not a licensed bank or financial institution and EURST does not handle clients’ funds at any time. EURST (also known as “Euro Stable Token”) is an ERC-20 euro stablecoin backed with FDIC-insured USD held in a trust managed by PT for the benefits of EURST token holders (“EURST Holders”). PT is the regulated trustee and official EURST issuer/administrator. EURST tokens themselves are not FDIC-insured. Only EURST users registered via this website, and verified by PT for Bank Secrecy Act (BSA) and Anti-Money Laundering (AML) compliance are allowed to deposit funds into or redeem/withdraw funds. Third-party deposits into EURST accounts are prohibited. PT reserves the right to deny, suspend or terminate anyone’s participation in the EURST Trust and/ or usage of EURST accounts if PT deems it advisable or necessary to comply with applicable laws or to eliminate practices that are not consistent with laws, rules, regulations or best practices. Nothing contained herein shall be considered financial advice or recommendation to buy/sell any security, commodity, cryptocurrency or any other financial instrument. Investing has many risks, including risk of losses beyond principal investment. Past performance is not indicative of future results</p>
      </div>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Header',
  components: {
  }
}
</script>
