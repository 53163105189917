<template>
  <div class="about darkpage">
    <Header />
    <div class="container-fluid sec1">
      <img src="../assets/images/about-sec1-pc.png" alt="" class="bg" />
      <div class="row">
        <h2>
          Banking <br />
          on The Future
        </h2>
      </div>
    </div>
    <div class="container-fluid partners py-5">
      <div class="container">
        <h3>Partners</h3>
        <div class="row desktop">
          <div class="col">
            <img src="../assets/images/partners_(1).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(2).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(3).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(4).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(5).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(6).png" alt="" />
          </div>
        </div>

        <div class="row mobile">
          <div class="col">
            <img src="../assets/images/partners_(1).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(2).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(3).png" alt="" />
          </div>
        </div>
        <div class="row mobile">
          <div class="col">
            <img src="../assets/images/partners_(4).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(5).png" alt="" />
          </div>
          <div class="col">
            <img src="../assets/images/partners_(6).png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sec3">
      <div class="con">
        <h3 class="mb-5">
          <span>Upgrading with new standards</span>
          <br class="d-md-block d-none" />
          for a faster evolution of the European economy
        </h3>
        <div class="ban">
          <img src="../assets/images/Looper_BG.png" alt="" class="bg" />
          <h4>
            “We Deliver Compliant and Borderless Instruments for the New
            Financial Era”
          </h4>
          <p>Simone Mazzuca, Founder & CEO</p>
          <div class="d-flex">
            <a target="_blank" href="https://www.linkedin.com/in/simone-mazzuca-/"><i class="fa-brands fa-linkedin-in"></i></a>
            <a target="_blank" href="https://twitter.com/Simone_Mazzuca_"><i class="fa-brands fa-twitter"></i></a>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <img src="../assets/images/howwestarted.png" alt="" />
            <h4>How we started</h4>
            <p>
              EURST was built as a result of savouring all the benefits of the
              digital assets, and the necessary security and transparency
              pillars, which can allow to the institutions and businesses to
              start accepting stablecoins safely.
            </p>
          </div>
          <div class="item">
            <img src="../assets/images/mission.png" alt="" />
            <h4>Mission</h4>
            <p>
              Our mission is to provide today’s market and institutions, the
              most reliable and transparent stablecoin, which can be used on
              large scale. We aim to give the benefit to all unbanked users to
              be able to transact globally - fast, easy and with low fees.
            </p>
          </div>
          <div class="item">
            <img src="../assets/images/vision.png" alt="" />
            <h4>Vision</h4>
            <p>
              We put transparency first for a healthy economy, and make sure that EURST provides all of the necessary assurances for the users that receive the best benefits from their digital assets, while staying secure and compliant. We believe stablecoins are the bridge between the traditional assets and digital assets space, and only the products which put the users and transparency first can.
            </p>
          </div>
          <div class="item">
            <img src="../assets/images/goals.png" alt="" />
            <h4>Goals</h4>
            <p>
              EURST is working towards enabling businesses, merchants and
              institutions to integrate stablecoins. We are working to meet the
              regulators’ and institutions’ expectations for the better
              understanding and implementation of the stablecoins as a payment
              method, and we aim to let businesses and institutions to start
              accepting stablecoins on a large scale.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sec4 py-5">
      <img src="../assets/images/map.png" alt="" class="bg" />
      <div class="container">
        <div class="row joinus">
          <div class="col-md-6">
            <h3>
              Join Us to Build the <br />
              Future of Finance
            </h3>
            <a href="https://k9654f4dt2i.typeform.com/JoinUs">
              <button>
                SEND US YOUR CV <i class="fa-solid fa-arrow-up-from-bracket"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.sec1 {
  position: relative;
}

.sec1 .row {
  min-height: 70vh;
  align-items: center;
}

.sec1 .bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

.sec1 h2 {
  text-align: center;
  font-weight: 700;
  font-size: 4rem;
  color: #a1c4fd;
  margin: 0 auto;
}

.partners {
  background: #f5f6f7;
}

.partners h3 {
  text-align: center;
  font-weight: 600;
  color: #1b2c42;
  margin-bottom: 1em;
}

.partners img {
  height: 1.5rem;
  margin: 1em 0;
}

.sec3 {
  padding: 2rem 5%;
}

.sec3 .con {
  width: 100%;
  height: 100%;
  background: #f5f6f7;
  border-radius: 2rem;
  padding: 3rem 5%;
}

.sec3 h3 {
  color: #616c7c;
  font-weight: 600;
  font-size: 28px;
}

.sec3 h3 span {
  color: #1b2c42;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 36px !important;
  font-family: "Inter";
  color: #0F2138;
}

.sec3 .ban {
  padding: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

.sec3 .ban .bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right bottom;
  filter: invert();
}

.sec3 .ban::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(100% 761.86% at 0% 0%, #424244 0%, #2b2b2c 100%);
  z-index: -2;
}

.sec3 .ban h4 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  max-width: 600px;
  color: white;
}

.sec3 .ban p {
  color: #c3c8cd;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.sec3 .ban .foun {
  transform: translate(-2rem, 2rem);
}

.sec3 .ban a {
  width: 2rem;
  height: 2rem;
  background: white;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  z-index: 100;
}

.sec3 .list {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sec3 .list .item {
  width: calc(50% - 0.5rem);
  margin: 0.5rem 0;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.sec3 .list .item:nth-child(2n-1) {
  margin-right: 1rem;
}

.sec3 .list .item h4 {
  color: #1b2c42;
  font-weight: 600;
}

.sec3 .list .item p {
  color: #616c7c;
  font-size: 0.9rem;
}

.sec3 .list .item a {
  margin-left: auto;
  margin-top: auto;
  padding-top: 2rem;
}

.sec3 .list .item a img {
  width: 3.5rem;
}

.sec4 {
  background: #1d1d1d;
  position: relative;
}

.sec4 .row {
  min-height: 40vh;
  align-items: flex-end;
}

.sec4 h3 {
  font-weight: 600;
  color: white;
}

.sec4 button {
  margin-top: 1rem;
  border: none;
  padding: 0.5em 1em;
  border-radius: 2em;
  background: #2058e4;
  color: white;
  font-size: 16px;
}

.sec4 button i {
  margin-left: 1ch;
}

.sec4 .bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  object-position: bottom;
}

@media screen and (max-width: 769px) {
  .sec1 .row {
    padding-top: 5rem;
    height: 90vh;
  }

  .sec1 h2 {
    font-size: max(2.5rem, 10vw);
  }

  .sec3 {
    padding-inline: 0;
  }

  .sec3 .list .item {
    width: calc(100%);
  }

  .sec4{
    padding-bottom: 0 !important;
  }

  .sec4 .row {
    align-items: center;
    text-align: center;
    margin-top: 5rem;
  }
}

.joinus h3 {
  font-size: 48px !important;
  line-height: 1.2 !important;
}
.item img {
  max-width: 80px;
  margin-bottom: 30px;
}
@media (min-width: 601px) {
  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }
}
@media (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display :flex;
  }
  .sec4 .bg[data-v-4cebd208] {
    width: 100%;
    height: auto;
    top: 10%;
    -o-object-fit: cover;
    object-fit: contain;
  }
}
</style>
