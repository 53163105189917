<template>
  <div class="home">
    <Header />
    <div class="container-fluid sec1">
      <img class="bg" src="../assets/images/Looper_BG.png" alt="" />
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>STABLECOINS</h2>
            <p>
              EURST is just the beginning. We are on a mission to provide
              stablecoins on a large scale to institutions, businesses and
              individuals and enable the new stablecoins era. We are launching
              the full specter of the most used currencies, in their digital
              representation, built on the same core – complete transparency,
              escrowed collateral and real-time attested.
            </p>
          </div>
          <div class="col-md-6 d-flex">
            <img src="../assets/images/eurst.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sec2">
      <div class="con">
        <div class="container">
          <h3>Our Stablecoins</h3>
          <div class="list">
            <div class="item" id="eurst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/eurst_item.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>EURST,</span> an euro stablecoin, representing the
                    digital value of 1 EUR
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p>0xB1aBd7ABa7d99BBEfb33D1dfc66B0dD522335350</p>
                    <button class="token_btn">On Mainnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="https://etherscan.io/token/0xB1aBd7ABa7d99BBEfb33D1dfc66B0dD522335350">Etherscan</a></li>
                    <li><a href="https://www.coingecko.com/en/coins/euro-stable-token">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="#">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href="/uploads/WhitePaper_EURST.pdf"
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="usdst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/usdst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>USDST,</span> a US dollar stablecoin, representing the
                    digital value of 1 USD
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p>0xc4f392B480A16Ce3A0bCD20De78c885DCA5aFbd0</p>
                    <button class="token_btn">On Mainnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href="/uploads/WhitePaper_USDST.pdf"
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="hkdst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/hkdst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>HKDST,</span> a HK dollar stablecoin, representing the
                    digital value of 1 HKD
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p>0x180ed78465a4C5B362446b129d7715c167e62176</p>
                    <button class="token_btn">On Mainnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href="/uploads/WhitePaper_HKDST.pdf"
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="gbpst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/gbpst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>GBPST,</span> a pound sterling stablecoin,
                    representing the digital value of 1 GBP
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p>0x9D2579413Eb8F8843Db8f17102241b4B402D6EC1</p>
                    <button class="token_btn">On Mainnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href="/uploads/WhitePaper_GBPST.pdf"
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="aedst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/aedst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> AEDST,</span> an Emirati Dirham stablecoin,
                    representing the digital value of 1 AED
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="audst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/audst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> AUDST,</span> an Australian dollar stablecoin,
                    representing the digital value of 1 AUD
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="bgnst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/bgnst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> BGNST,</span> a Bulgarian lev stablecoin,
                    representing the digital value of 1 BGN
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="brzst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/brzst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> BRZST,</span> a Brazilian real stablecoin,
                    representing the digital value of 1 BRL
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="cadst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/cadst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> CADST,</span> an Canadian dollar stablecoin,
                    representing the digital value of 1 CAD
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="jpyst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/jpyst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>JPYST,</span> a Japanese yen stablecoin, representing
                    the digital value of 1 JPY
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="mxnst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/mxnst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> MXNST,</span> a Mexican peso stablecoin, representing
                    the digital value of 1 MXN
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="ronst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/ronst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> RONST,</span> a Romanian leu stablecoin, representing
                    the digital value of 1 RON
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="rsst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/rsst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span> RSST,</span> a Indian rupee stablecoin, representing
                    the digital value of 1 INR
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="item" id="zarst">
              <div class="row">
                <div class="col-md-1">
                  <img src="../assets/images/zarst.png" alt="" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <h4>
                    <span>ZARST,</span> a South African rand stablecoin,
                    representing the digital value of 1 ZAR
                  </h4>
                  <div class="token">
                    <h5>Token address</h5>
                    <p></p>
                    <button class="token_btn">On Testnet</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li><a href="">Etherscan</a></li>
                    <li><a href="">CoinGecko</a></li>
                    <li><a href="">CoinMarketCap</a></li>
                    <li><a href="">GitHub</a></li>
                  </ul>
                  <a class="item-btn" target="_blank" href=""
                    >WHITEPAPER
                    <img src="../assets/images/arrow-right-black.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.sec1 {
  position: relative;
  padding-top: 5rem;
}

.sec1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fafbfb;
  z-index: -2;
}

.bg {
  position: absolute;
  bottom: 0;
  width: 99%;
  object-fit: contain;
  z-index: -1;
}
.sec1 .row {
  align-items: center;
  min-height: 60vh;
  padding: 0 3rem;
}

.sec1 h2 {
  font-size: 4rem;
  color: #1b2c42;
  font-weight: 800;
}

.sec1 p {
  color: #616c7c;
}

.sec1 .col-md-6:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec1 .col-md-6 img {
  width: min(400px, 60%);
  height: min(400px, 60%);
  object-fit: contain;
  margin: auto;
  border: 2px solid white;
  border-radius: 50%;
}

.sec2 .con {
  margin: 3rem 5%;
  padding: 3rem 2rem;
  background: #f5f6f7;
  border-radius: 2rem;
}

.sec2 h3 {
  text-align: center;
  padding-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.05em;
}

.sec2 .list {
  display: flex;
  flex-direction: column;
}

.sec2 .item {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  overflow: hidden;
  margin: 1rem 0;
}

.sec2 .item.active {
  background: #2058e4;
}

.sec2 .item img {
  width: 3.5rem;
}

.sec2 .item h4 {
  color: #616c7c;
  font-size: 1.5rem;
  font-weight: 600;
}
.sec2 .item h4 span {
  color: #1b2c42;
  font-size: 1.5rem;
  font-weight: 600;
}

.sec2 .item.active h4 {
  color: #c3c8cd;
}

.sec2 .item.active h4 span {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.sec2 .item .col-md-12 col-lg-8 {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
.sec2 .item .col-lg-8 {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.sec2 .item .token {
  margin-top: auto;
  display: grid;
  grid-template-columns: auto 8rem;
  grid-template-rows: auto auto;
  align-items: center;
}

.sec2 .token h5 {
  color: #1b2c42;
  font-size: 1.1rem;
}

.sec2 .item.active .token h5 {
  color: white;
}

.sec2 .item .token p {
  margin: 0;
  font-size: 0.85rem;
  color: #616c7c;
  word-wrap: anywhere;
}

.sec2 .item.active .token p {
  color: #c3c8cd;
}

.sec2 .item .token .token_btn {
  grid-column: 2;
  grid-row: 1/3;
  font-size: 0.9rem;
  margin-top: auto;
  border: none;
  background: #2058e4;
  padding: 0.5em 1em;
  color: white;
  border-radius: 2rem;
}

.sec2 .item.active .token .token_btn {
  background-color: white;
  color: #2058e4;
}

.sec2 .item .col-md-3 {
  background: #ecedef;
  box-shadow: 2rem 0 0 2rem #ecedef;
  padding: 0rem 2rem;
  max-width: 200px;
  margin-left: auto;
}
.sec2 .item .col-lg-3 {
  background: #ecedef;
  box-shadow: 2rem 0 0 2rem #ecedef;
  padding: 0rem 2rem;
  max-width: 200px;
  margin-left: auto;
}

@media (max-height: 500px) and (max-width: 1000px){
  .sec2 .item .col-md-3 {
    margin-top: 50px;
  }
  .sec2 .item .col-md-12 col-lg-8 {
    max-width: 100% !important;
    flex: 0 0 110% !important;
  }
  .sec2 .item .col-lg-3 {
    margin-top: 50px;
  }
  .sec2 .item .col-lg-8 {
    max-width: 100% !important;
    flex: 0 0 110% !important;
  }
}

.sec2 .item.active .col-md-3 {
  background: #1b49be;
  box-shadow: 2rem 0 0 2rem #1b49be;
}
.sec2 .item.active .col-lg-3 {
  background: #1b49be;
  box-shadow: 2rem 0 0 2rem #1b49be;
}

.sec2 .item h6 {
  color: #1b2c42;
  font-weight: 500;
  font-size: 1.1rem;
}

.sec2 .item.active h6 {
  color: white;
}

.sec2 .item ul {
  padding: 0;
  list-style: none;
}

.sec2 .item ul li {
  margin: 0.3em 0;
}
ul li {
  display: block;
}

.sec2 .item ul a {
  color: #616c7c;
}

.sec2 .item.active ul a {
  color: #c3c8cd;
}

.sec2 .item .item-btn {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 600;
  color: #1b2c42;
  text-decoration: none;
}

.sec2 .item.active .item-btn {
  color: white;
}

.sec2 .item .item-btn img {
  width: 1rem;
  margin-left: 0.5rem;
}

.sec2 .item.active .item-btn img {
  filter: invert();
}

@media(max-width: 900px) and (min-width:610px){
  .sec2 .item .col-md-3 {
    margin-top: 50px;
  }
  .sec2 .item .col-lg-3 {
    margin-top: 50px;
  }
}

@media screen and (max-width: 769px) {
  .sec1 .row{
    padding: 0;
  }

  .sec1 {
    padding: 8rem 0 3rem;
  }

  .sec1 h2{
    font-size: 2rem;
  }

  .sec1 .col-md-6 img{
    margin-top: 3rem;
  }
  
  .sec2 h3 {
    font-size: 2rem;
  }

  .sec2{
    padding: 0;
  }

  .sec2 .con {
    padding-inline: 5px;
  }

  .sec2 .item {
    margin: 1.5rem 0;
  }

  .sec2 .item h4 {
    margin: 1.5em 0;
    font-size: 1.1rem;
    color: #616c7c;
  }

  .sec2 .item h4 span {
    color: #1b2c42;
  }

  .sec2 .item .token {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sec2 .item .token p {
    word-wrap: break-word;
    width: 100%;
    margin: 0.75em 0 1.5em;
  }

  .sec2 .item .col-md-3 {
    background: #ecedef;
    box-shadow: 0rem 5rem 0 5rem #ecedef;
    max-width: 100%;
    padding: 2rem;
    margin: 0;
    margin-top: 3rem;
  }

  .sec2 .item.active .col-md-3 {
    background: #1b49be;
    box-shadow: 0rem 5rem 0 5rem #1b49be;
  }
}
</style>