<template>
  <header id="header" :class="headerClass">
    <div class="container">
      <div class="row">
        <span id="menu-btn" @click="addMenuClass" :class="{ 'active' : isMenuActive }"></span>
        <div class="col-md-8 align-self-center">
          <RouterLink to="/" class="logo">
            <img id="logo" src="../assets/images/logo.svg" alt="Logo">
          </RouterLink>
          <div id="navbar" :class="{ 'active' : isMenuActive }">
            <ul class="menu">
              <li><RouterLink to="/stablecoins">Stablecoins</RouterLink></li>
              <li><RouterLink to="/solution">Solutions</RouterLink></li>
              <li><RouterLink to="/about">About Us</RouterLink></li>
              <li><RouterLink to="/news">News</RouterLink></li>
              <li class=""><a href="#">Login</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 align-self-center text-md-right d-none d-lg-block">
          <a id="signin" href="https://app.eurst.io/login" class="btn-style primary-arrow white-bg transparent">Sign In</a>
          <a id="signup" href="https://app.eurst.io/choose-acc-type" class="btn-style primary-arrow d-lg-none d-xl-inline-block">Sign Up</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Header',
  props: ['headerClass'],
  components: {
  },
  data(){
    return{
      isMenuActive: false,
    }
  },
  methods:{
    addMenuClass(){
      this.isMenuActive = !this.isMenuActive
      if (this.isMenuActive){
        document.body.classList.add('active')
      }else {
        document.body.classList.remove('active')
      }
    },
    handleSCroll() {
      let header = document.querySelector("header");
      if (window.scrollY >= 100 && !header.className.includes('sticky')) {
        header.classList.add('sticky');
      } else if (window.scrollY <= 100) {
        header.classList.remove('sticky');
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleSCroll);
  }
}
</script>
