<template>
  <div class="home">
    <Header/>

    <Banner primaryBanner="active" buttonLink="" primaryBannerTag="THE EURO STABLE TOKEN" primaryBannerTitle="DIGITIZING <br/> €URO CURRENCY"
            primaryBannerDescription="for the reinvention of the European economy"/>


    <section id="available-in">
      <div class="container">
        <h2 class="small white">Available In</h2>
        <ul class="brand">
          <li><a target="_blank" href="https://metamask.io/"><img style="filter: none;" src="../assets/images/metamask.png" alt=""></a></li>
          <li><a  target="_blank" href="https://fmfw.io/"><img src="../assets/images/fmfw.svg" alt=""></a></li>
          <li><a target="_blank"  href="https://www.bitgo.com/"><img style="filter: none;" src="../assets/images/bitgo.svg" alt=""></a></li>
          <li><a  target="_blank" href="https://hitbtc.com/"><img style="filter: none;" src="../assets/images/hitbtc.png" alt=""></a></li>
          <li><a  target="_blank" href="https://uniswap.org/"><img src="../assets/images/uniswap.png" alt=""></a></li>
          <li><a  target="_blank" href="https://indacoin.io/"><img src="../assets/images/indacoin.svg" alt=""></a></li>
          <li><a  target="_blank" href="https://pro.changelly.com/"><img src="../assets/images/changelly.png" alt=""></a></li>
        </ul>
      </div>
    </section>

    <section id="join-eurst">
      <div class="container">
        <h2 class="white">
          Join EURST, The first representative Euro stablecoin, <span>100% collateralized with US Dollars, offering maximum transparency to asset backing through independent, real-time attestation reporting.
        </span></h2>
        <a href="/stablecoins" class="btn-style primary-arrow white-bg">Stablecoins</a>
        <a href="https://real-time-attest.trustexplorer.io/wallex" class="btn-style primary-arrow transparent white-transparent">Real Time Attestation</a>
      </div>
    </section>

    <section id="currencies">
      <div class="container">
        <h2 class="left">Currencies</h2>
        <carousel :margin="32" :items="4" :autoplay="true" :responsive="{0:{items:1,nav:false, loop:true, margin:16 },768:{items:3, nav:true, loop:true },1600:{items:4, nav:true, loop:true }}">
          <div class="item">
            <div class="currencies-card">
              <div class="img-wrapper">
                <img src="../assets/images/currency-logo-1.png" alt="How work">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card">
              <div class="img-wrapper">
                <img src="../assets/images/currency-logo-2.png" alt="How work">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card">
              <div class="img-wrapper">
                <img src="../assets/images/currency-logo-3.png" alt="How work">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card">
              <div class="img-wrapper">
                <img src="../assets/images/currency-logo-4.png" alt="How work">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper" style="margin-top: 15px;">
                <img src="../assets/images/AEDST-black-symbol 1.png" alt="How work">
                <span>AEDST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/BGNST-black-symbol 1.png" alt="How work">
                <span>BGNST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/BRZST-black-symbol 1.png" alt="How work">
                <span>BRZST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/CADST-black-symbol 1.png" alt="How work">
                <span>CADST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper" style="margin-top: 0px;">
                <img src="../assets/images/JPYST-black-symbol 1.png" alt="How work">
                <span>JPYST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/MXNST-black-symbol 1.png" alt="How work">
                <span>MXNST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/RONST-black-symbol 1.png" alt="How work">
                <span>RONST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper">
                <img src="../assets/images/RSST-black-symbol 1.png" alt="How work">
                <span>RSST</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="currencies-card new">
              <div class="img-wrapper" style="margin: 0px;">
                <img src="../assets/images/ZARST-black-symbol 1.png" alt="How work">
                <span>ZARST</span>
              </div>
            </div>
          </div>
        </carousel>

      </div>
    </section>

    <section id="how-it-work">
      <div class="container">
        <div class="how-it-work-wrapper">
          <h2 class="white">How It Works</h2>
          <div class="row row-eq-height">
            <div class="col-md-7 how-it-work-card-wrapper">
              <div class="how-it-work-card">
                <div class="img-wrapper">
                  <img src="../assets/images/icons/how-it-work-signup-icon.svg" alt="">
                </div>
                <div class="content-wrapper">
                  <h3 class="h3">Sign up</h3>
                  <ul>
                    <li><p>Open your free account here</p></li>
                    <li><p>Pass the KYC check, and start depositing, minting or redeeming.</p></li>
                    <li><p>Provide the Ethereum address where you’ll receive the EURST tokens.</p></li>
                  </ul>
                  <p>Accounts available for Individuals and Businesses. Getting registered
                    on EURST is the only way to be able to mint and redeem tokens at any time,
                    and provides you with a custodian account where you can safely hold your funds
                    in custody.</p>
                  <h3 class="counter">01</h3>
                </div>
              </div>
            </div>
            <div class="col-md-5 how-it-work-card-wrapper">
              <div class="how-it-work-card">
                <div class="img-wrapper">
                  <img src="../assets/images/icons/how-it-work-mint-icon.svg" alt="">
                </div>
                <div class="content-wrapper">
                  <h3 class="h3">Mint EURST</h3>
                  <p>There are no pre-minted tokens, EURST is only minted for circulation
                    on the Ethereum Blockchain upon demand, by verified eurst.io users. Anyone
                    registered and verified on EURST can mint via bank transfer or directly with card.
                    Funds are directly allocated to the escrow collateral account, and held there for
                    the benefit of the EURST holders.</p>
                  <h3 class="counter">02</h3>
                </div>
              </div>
            </div>

            <div class="col-md-4 how-it-work-card-wrapper">
              <div class="how-it-work-card">
                <div class="img-wrapper">
                  <img src="../assets/images/icons/how-it-work-spend-icon.svg" alt="">
                </div>
                <div class="content-wrapper">
                  <h3 class="h3">Spend</h3>
                  <p>EURST is an ERC-20 token, which makes it easy and straight forward to be integrated.
                    <br>EURST can be used for trading, spending and as a reliable store value of money.</p>
                  <h3 class="counter">03</h3>
                </div>
              </div>
            </div>
            <div class="col-md-4 how-it-work-card-wrapper">
              <div class="how-it-work-card">
                <div class="img-wrapper">
                  <img src="../assets/images/icons/how-it-work-redeem-icon.svg" alt="">
                </div>
                <div class="content-wrapper">
                  <h3 class="h3">Redeem</h3>
                  <p>Key feature of EURST is the redeem function. Everyone who is registered
                    and verified on eurst.io can redeem, without limitations. Unlike other
                    stable coins, the redeem function allows users to rest assured they can always
                    be able to redeem their EURST tokens for fiat money.</p>
                  <h3 class="counter">04</h3>
                </div>
              </div>
            </div>
            <div class="col-md-4 how-it-work-card-wrapper">
              <div class="how-it-work-card">
                <div class="img-wrapper">
                  <img src="../assets/images/icons/how-it-work-withdraw-icon.svg" alt="">
                </div>
                <div class="content-wrapper">
                  <h3 class="h3">Withdraw</h3>
                  <p>Registered users can withdraw the funds to another bank account, for no fees. <br>
                    To withdraw, you should provide via your account the wire instructions and the transfer will be sent instantly.</p>
                  <h3 class="counter">05</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="who-we-serve">
      <div class="container">
        <div class="who-we-serve-wrapper">
          <h2>Who We Serve</h2>
          <h3 class="h3">Institutional-grade digital euro currency for the new economy,
            <strong>EURST is the most reliable stable coin which can serve for payments, trading,
            savings, escrow and institutional use</strong></h3>
          <div class="img-wrapper">
            <img src="../assets/images/who-we-are-img.png" alt="">
          </div>
          <div class="row row-eq-height">
            <div class="col-md-6">
              <div class="who-we-serve-card">
                <div class="row">
                  <div class="col-xl-7 col-md-12">
                    <div class="content-wrapper">
                      <h3 class="h3">Financial <br>
                        Institutions</h3>
                      <p>The Complete Platform Integrated Liquidity, Lending and
                        Custody for building new generation Digital Asset Financial
                        Services.</p>
                    </div>
                  </div>
                  <div class="col-md-5"></div>
                </div>
                <div class="img-wrapper">
                  <img src="../assets/images/who-we-serve-img-1.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="who-we-serve-card">
                <div class="row">
                  <div class="col-xl-7 col-md-12">
                    <div class="content-wrapper">
                      <h3 class="h3">Individuals</h3>
                      <p>Increase and protect your wealth. Use digital
                        euro as a digital saving account or for trading
                        against the volatility.</p>
                    </div>
                  </div>
                  <div class="col-md-5"></div>
                </div>
                <div class="img-wrapper">
                  <img src="../assets/images/who-we-serve-img-2.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="who-we-serve-card">
                <div class="row">
                  <div class="col-xl-7 col-md-12">
                    <div class="content-wrapper">
                      <h3 class="h3">Businesses</h3>
                      <p>Expand your business. Use digital euro to send
                        and receive International instant payments for free.</p>
                    </div>
                  </div>
                  <div class="col-md-5"></div>
                </div>
                <div class="img-wrapper">
                  <img src="../assets/images/who-we-serve-img-3.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="who-we-serve-card">
                <div class="row">
                  <div class="col-xl-8 col-md-12">
                    <div class="content-wrapper">
                      <h3 class="h3">Crypto <br>
                        Native Companies</h3>
                      <p>Integrate an alternative, secure, transparent and
                        unique digital euro for built trust in your digital
                        asset <br> financial services.</p>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                </div>
                <div class="img-wrapper">
                  <img src="../assets/images/who-we-serve-img-4.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="use-case">
      <carousel :margin="32" :items="1" :autoplay="true" :responsive="{0:{items:1,nav:false, loop:true, margin:16 },768:{items:1, nav:true, loop:true },1600:{items:1, nav:true, loop:true }}">
        <div class="item">
          <img src="../assets/images/use-case-img-1.png" class="slider-img" alt="">
          <div class="container">
            <a href="solution" class="btn-style">Use Cases</a>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/use-case-img-2.png" class="slider-img" alt="">
          <div class="container">
            <a href="solution" class="btn-style">Use Cases</a>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/use-case-img-3.png" class="slider-img" alt="">
          <div class="container">
            <a href="solution" class="btn-style">Use Cases</a>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/use-case-img-4.png" class="slider-img" alt="">
          <div class="container">
            <a href="solution" class="btn-style">Use Cases</a>
          </div>
        </div>
      </carousel>
    </section>

    <section id="live-audit">
      <div class="container text-center">
        <h2 class="white">How Real Time Attestations Works</h2>
        <h3 class="h3 white">EURST has in place
          <strong>In order to offer customers and EURST token holders the highest level of visibility to the assets collateralizing the EURST token, 
            we engage an independent CPA to perform attestations under AICPA standards. As opposed to collecting outstanding token balances and total 
            collateral assets at month-end, our CPA is engaged to collect these asset and liabilities balances in real-time, using their proprietary technology, 
            LedgerLensTM Real Time Reserves. The LedgerLens system is integrated via API to the underlying trust account holding assets on behalf of token holders,
             as well as the Ethereum blockchain and the EURST smart contract to collect circulating supply of EURST. Data for assets and liabilities is collected every 
             30 seconds and presented on the LedgerLens widget (below). The system also generates examination opinions using the real-time data collected and makes those 
             reports downloadable for intended users.</strong>
        </h3>
        <div class="dashboard">
          <h2 class="white">Real Time Attestation Dashboard</h2>
          <div class="img-wrapper">
            <img src="../assets/images/realtime-dashboard-img.png" alt="">
          </div>
        </div>
          <div class="live-attestation">
            <h3 class="white">"EURST is, and will always be <br>
              100% asset-backed"</h3>
            <!-- <a href="https://real-time-attest.trustexplorer.io/wallex" class="btn-style primary-arrow white-bg">VIEW REAL TIME ATTESTATIONS</a> -->
            <iframe class="attestationwidget" title="iframe-title" src="https://widget.real-time-reserves.ledgerlens.io/v1/wallex" width="498" height="614"></iframe>
        </div>
      </div>
    </section>

    <section id="more-about-eurst">
      <div class="container">
        <h3 class="h3">FIND MORE ABOUT EURST,<br>
          <strong> in these leading publications</strong></h3>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="leading-publications-card">
              <div class="img-wrapper">
                <img src="../assets/images/leading-publications-img-1.png" alt="">
              </div>
              <div class="content-wrapper">
                <p>“EURST is an example for the European economy,
                  but for us EURST is also just the beginning, it’s
                  a taste of what can be done. We aspire to build the
                  system and to unify the people.”</p>
                <a href="https://en.cryptonomist.ch/2021/04/14/launch-eurst-stablecoin/" class="btn-style primary-arrow transparent">Read More</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="leading-publications-card">
              <div class="img-wrapper">
                <img src="../assets/images/leading-publications-img-2.png" alt="">
              </div>
              <div class="content-wrapper">
                <p>“EURST can be used as a logistical background for the representation of the Euro”</p>
                <a href="https://news.bitcoin.com/eurst-stablecoin-reinvention-of-the-european-economy/" class="btn-style primary-arrow transparent">Read More</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="leading-publications-card">
              <div class="img-wrapper">
                <img src="../assets/images/leading-publications-img-3.png" alt="">
              </div>
              <div class="content-wrapper">
                <p>“EURST Hosts Event to Discuss Future and Regulations of Stable Coins”</p>
                <a href="https://beincrypto.com/eurst-leaders-discuss-future-and-regulations-of-stable-coins/" class="btn-style primary-arrow transparent">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="partnership">
      <div class="container">
        <div class="partnership-wrapper">
          <h2 class="white">Partnership</h2>
          <div class="row row-eq-height">
            <div class="col-md-6">
              <div class="leading-publications-card">
                <div class="content-wrapper">
                  <h3 class="h3">Partnership Inquiry</h3>
                  <p>We work with various merchants, businesses, causes and
                    others on the adoption of stablecoins and in showcasing the benefits of EURST. <br><br>
                    We are on a mission to promote transparency, high standards of compliance
                    and the various uses of stable coins. If your vision aligns with ours, we’ll
                    be glad to talk.</p>
                  <a href="#" class="btn-style primary-arrow transparent">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="leading-publications-card">
                <div class="content-wrapper">
                  <h3 class="h3">Institutional Inquiry</h3>
                  <p>Enter the digital assets sector and expand your portfolio by adopting a reliable EURO stablecoin. <br><br>
                    The digital assets space is yet in its early stage and is set for rapid growth. <br><br>
                    EURST is engineered with a secure institutional level structure, and the highest
                    compliance standards, which assure institutions that they can incorporate a reliable
                    euro stablecoin for deposits, transactions, escrow, investment and card payments.</p>
                  <a href="#" class="btn-style primary-arrow transparent">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about-stablecoins">
      <div class="container">
        <center>
        <div class="video-wrapper">
          <video class="videoHome" playsinline autoplay muted loop>
            <source src="https://eurst.hexcom.solutions/video2.mp4" type="video/mp4">
          </video> 
          <!-- <iframe width="560" height="315" src="https://eurst.hexcom.solutions/video2.mp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          <div class="play-icon" v-b-modal.about-stablecoins-intro>
            <!-- <img src="../assets/images/icons/play-icon.svg" alt=""> -->
          </div>
        </div>
        </center>
        <h2>About Stablecoins</h2>
        <div class="row">
          <div class="col-md-12">
            <div class="leading-publications-card">
              <div class="content-wrapper">
                <h3 class="h3">What is a stablecoin?</h3>
                <p>A stablecoin is a digital currency on the blockchain that is pegged to a “stable” reserve asset like the U.S. dollar or gold.
                  Stablecoins are designed to reduce volatility relative to unpegged cryptocurrencies like Bitcoin, and be used as a regular payment instrument.</p>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12">
            <div class="leading-publications-card">
              <div class="content-wrapper">
                <h3 class="h3">Is EURST secure?</h3>
                <p>EURST is built on the ERC-20 standard, and on the Ethereum network – the most used and thus tested blockchain. <br> 
                  Written by some of the best engineers in smart contract writing, the contract has subsequently passed security audit with no significant findings. <br>
                  Moreover, EURST’s workflow is regularly checked by its third party auditor to ensure it is safe for its users.</p>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12">
            <div class="leading-publications-card">
              <div class="content-wrapper">
                <h3 class="h3">Why real-time attestation is important?</h3>
                <p>Stablecoins are designed to have price stability and be “pegged” to the underlying assets. In order to maintain the price stability of EURST against the Euro, 
                  users need to know (1) that tokens are always collateralized 1:1 with underlying assets, and (2) that those underlying assets are redeemable for fiat currency on demand.
                  Because the price stability of a stablecoin is largely dependent on the tokenholder’s trust in 1:1 collateralization and redeemability, frequent attest reporting showing 1:1 
                  backing has become the industry standard means to create user trust in full collateralization and redeemability. While monthly reporting, made available after the month-end has 
                  been widely adopted, we wanted to do more to give our customers 
                  real-time visibility to EURST’s collateral backing and thus make those those attestations available in real time. </p>
              </div>
            </div>
          </div>
        </div>
        <!--
        <h3>What is a stablecoin?</h3>
        <p>A stablecoin is a digital currency on the blockchain that is pegged to a “stable” reserve asset like the U.S. dollar or gold. Stablecoins are designed to reduce volatility relative to unpegged cryptocurrencies like Bitcoin, and be used as a regular payment instrument.</p>

        <h3>Is EURST secure?</h3>
        <p>EURST is built on the ERC-20 standard, and on the Ethereum network – the most used and thus tested blockchain. <br> <br>
          Written by some of the best engineers in smart contract writing, the contract has subsequently passed security audit with no significant findings. <br> <br>
          Moreover, EURST’s workflow is regularly checked by its third party auditor to ensure it is safe for its users.</p>

        <h3>Why live audit matters?</h3>
        <p>Real-time attest is the process whereby an independent accountant collects via technological means sufficient audit evidence, in order to issue public audit reports on the off-chain data of the amount of fiat assets in reserve, and the on-chain data of tokens in circulation. <br>
          The current standards for stablecoins recognize the live-audit as an industry grade assurance for users, integrators and regulators, that the tokens are actually 100% asset backed.</p>
        -->
      </div>
    </section>

    <Footer/>

    <b-modal id="about-stablecoins-intro" title="BootstrapVue">
      <iframe width="560" height="315" src="https://eurst.hexcom.solutions/video.mp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import carousel from 'vue-owl-carousel';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    carousel
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
