<template>
  <div class="contact">
    <Header />
    <div class="container-fluid sec1">
      <div class="container px-4">
        <div class="row">
          <div class="col-md-6">
            <h3>Contacts</h3>
            <p>
              Our team is working on delivering the best customer service to
              you. Message us on the according department channel to help us get
              back to you faster than usual. <br /><br />
              In case you don’t find the according channel, get in touch with us
              through Intercom.
            </p>
            <a href="https://app.eurst.io/choose-acc-type"
              >Get started <img src="../assets/images/arrow-right-blue.png" alt=""
            /></a>
          </div>
          <div class="col-md-6">
            <img src="../assets/images/blueuser.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sec2">
      <img src="../assets/images/new_coins.png" class="bg d-md-block d-none" alt="" />
      <div class="container">
        <h3>For Enquiries</h3>
        <div class="row">
          <div class="col-md-7">
            <div class="item">
              <h4>Intercom</h4>
              <p>
                For any kind of enquiry for platform functionalities, you can
                live chat with us on our intercom channel <a href="">here</a>
              </p>
            </div>
            <div class="item">
              <h4>Press</h4>
              <p>
                For any enquiries related to marketing, press or information
                needed about EURST, you can email us at:
              </p>
              <a href="mailto:press@eurst.io">press@eurst.io</a>
            </div>
            <div class="item">
              <h4>General</h4>
              <p>For any general enquiries, you can email us at:</p>
              <a href="mailto:info@eurst.io">info@eurst.io</a>
            </div>
            <div class="item">
              <h4>Affiliates</h4>
              <p>
                For any enquires related to our Affiliates program, you can
                email us at:
              </p>
              <a href="mailto:affiliates@eurst.io">affiliates@eurst.io</a>
            </div>
            <div class="item">
              <h4>Sales</h4>
              <p>
                For any enquires, new partnerships or business opportunities,
                you can email us at:
              </p>
              <a href="mailto:sales@eurst.io">sales@eurst.io</a>
            </div>
            <div class="item">
              <h4>Ambassadors</h4>
              <p>
                For any enquires, new partnerships or business opportunities,
                you can email us at:
              </p>
              <a href="mailto:ambassadors@eurst.io">ambassadors@eurst.io</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contactmore">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="item">
              <img src="../assets/images/todayspecial.png"/>
              <div class="text">
                <h4>Want to know more?</h4>
                <p>Read more about EURST news on our <router-link to="/news">News section</router-link></p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="item">
              <img src="../assets/images/morequestions.png"/>
              <div class="text">
                <h4>More questions?</h4>
                <p>You can find everything you need to know on the <a href="">Help Centre</a></p>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
 export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.contactmore .item {
  display: flex;
}
.contactmore div img {
  width: 80px;
  height: 80px;
  margin-top: 20px;
}
.contactmore .col-sm-6 div {
  background: #fff;
  padding: 20px 35px 15px 35px;
  border-radius: 24px;
  margin-top: 10px;
  height: 100%;
}
.contactmore div h4 {
  font-weight: 600;
}
.contactmore {
  background: #f5f6f7;
  padding: 60px 0 60px 0;
}
.sec1 {
  padding: 10rem 0rem 5rem;
  background-image: url(../assets/images/contactbg.png);
  position: relative;
}

.sec1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #f5f6f7;
  z-index: -1;
}

.sec1 .row {
  align-items: center;
}

.sec1 img {
  width: min(80%, 300px);
  margin-left: auto;
}

.sec1 .col-md-6:last-child {
  display: flex;
}

.sec1 h3 {
  font-size: 3rem;
  color: #1b2c42;
  font-weight: 600;
}

.sec1 p {
  color: #616c7c;
  font-size: 0.9rem;
}

.sec1 a {
  display: flex;
  align-items: center;
  padding: 0.5em 1.5em;
  font-size: 0.8rem;
  color: white;
  text-decoration: none;
  border-radius: 1.5rem;
  background: #0054b8;
  width: max-content;
}

.sec1 a img {
  height: 1rem;
  width: 1rem;
  margin-left: 0.3em;
}

.sec2 {
  padding: 5rem 0;
  position: relative;
}

.sec2 .bg {
  position: absolute;
  top: 50%;
  right: 0;
  height: 70%;
  transform: translateY(-50%);
}

.sec2 h3 {
  font-size: 2rem;
  color: #1b2c42;
  font-weight: 600;
  margin-bottom: 1em;
}

.sec2 .col-md-7 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.sec2 .item {
  min-height: 250px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f6f7;
}

.sec2 .item:nth-child(2n-1) {
  border-left: none;
}

.sec2 .item:nth-child(2n) {
  border-right: none;
}

.sec2 .item:nth-child(-n + 2) {
  border-top: none;
}

.sec2 .item:nth-child(-n + 6) {
  border-bottom: none;
}

.sec2 .item h4 {
  font-weight: 600;
  color: #1b2c42;
}

.sec2 .item p {
  font-size: 0.9rem;
  color: #616c7c;
}

.sec2 .item a {
  font-size: 0.85rem;
}

@media screen and (max-width: 769px) {
  .sec1 h3 {
    font-size: 2.5rem;
  }

  .sec1 a {
    margin: 1rem 0 3rem;
  }

  .sec1 img {
    margin: auto;
  }
}

@media screen and (max-width: 425px) {
  .sec2 .col-md-7 {
    grid-template-columns: auto;
  }

  .sec2 .item {
    border: none;
    border-bottom: 2px solid #f5f6f7 !important;
  }

  .sec2 .item:last-child {
    border-bottom: none !important;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  .sec2 .bg {
    max-width: 400px;
    height: auto !important;
  }
}
</style>