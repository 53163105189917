<template>
  <div class="solutions darkpage">
    <Header />
    <div class="container-fluid sec1">
      <img src="../assets/images/sol-sec1-bg.svg" alt="" class="bg d-md-block d-none" />
      <img src="../assets/sol-sec1-bg-mobile.svg" alt="" class="bg d-block d-md-none" />
      <div class="row">
        <h2>Solutions</h2>
      </div>
    </div>
    <div class="container-fluid sec2">
      <div class="con">
        <div class="container">
          <div class="list">
            <div class="item">
              <h3>CUSTODY</h3>
              <p>
                EURST is a safe and secure storage solution for your
                stablecoins. New opportunities for reducing the risk and
                complications associated with managing your own crypto assets.
              </p>
              <img class="m-5 my-3" src="../assets/images/custody-pc.png" alt="" />
            </div>
            <div class="item">
              <h3>STAKING</h3>
              <p>
                Staking is the process of actively participating in transaction
                validation on a proof-of-stake blockchain. Lock your crypto in a
                golden cage and earn passive income or obtain rewards.
              </p>
              <img class="m-3" src="../assets/images/staking-pc.png" alt="" />
            </div>
            <div class="item">
              <h3>OTC</h3>
              <p>
                Over-The-Counter or OTC Trading in a framework of the crypto and
                Bitcoin space, is simply the trading of crypto assets directly
                between two parties. This literally is an action of two persons
                involved in a negotiation. Using EURST OTC desk you have a
                benefit of a fast solution against BTC volatility.
              </p>
              <img src="../assets/images/otc-pc.png" alt="" />
            </div>
            <div class="item">
              <h3>ESCROW</h3>
              <p>
                Easy way to finalize your contract. Escrow is the use of a
                third-party, which holds an asset or funds before they are
                transferred from one party to another. The third-party holds the
                funds until both parties have fulfilled their contractual
                requirements. Having your deal with EURST you have full
                blockchain transparency.
              </p>
              <img src="../assets/images/escrow-pc.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.sec1 {
  position: relative;
}

.sec1 .row {
  min-height: 70vh;
  align-items: center;
}

.sec1 .bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

.sec1 h2 {
  text-align: center;
  font-weight: 700;
  font-size: 4rem;
  color: #a1c4fd;
  margin: 0 auto;
}

.sec2 {
  padding: 4rem 2rem 2rem;
}

.sec2 .con {
  width: 100%;
  height: 100%;
  background: #f5f6f7;
  border-radius: 2rem;
  padding: 3rem 5%;
}

.sec2 .list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sec2 .list .item {
  position: relative;
  width: 45%;
  aspect-ratio: 1.1;
  min-height: 500px;
  padding: 2rem;
  border-radius: 1rem;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  align-items: flex-start;
}

.sec2 .list .item:nth-child(2n-1) {
  margin-right: 1rem;
}

.sec2 .list .item h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1b2c42;
}

.sec2 .list .item p {
  margin: 1rem 0 0;
  color: #616c7c;
}

.sec2 .list .item img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 55%;
  max-height: 270px;
  object-fit: contain;
  margin-left: auto;
}

@media screen and (max-width: 769px) {
  .sec1 .row {
    padding-top: 5rem;
    height: 90vh;
  }
  .sec2 .list .item img {
    max-height: 180px;
  }

  .sec1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    z-index: -2;
  }

  .sec1 .bg {
    height: 100%;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    top: auto;
  }

  .sec1 h2 {
    font-size: max(2.5rem, 10vw);
  }

  .sec2 {
    padding: 0;
  }

  .sec2 .list .item {
    width: 100%;
  }
}

@media screen and (max-width: 426px) {
  .sec2 .list .item:nth-child(3) {
    height: 600px;
  }

  .sec2 .list .item:nth-child(4) {
    height: 600px;
  }
  
}
@media (max-height: 1201px) and (max-width: 1000px) {
  .sec2 .list .item img {
    max-height: 150px !important;
  }
  .sec2 .list .item {
    min-height: 550px !important;
  }
}

@media (max-height: 1400px) and (min-width: 1000px) {
  .sec2 .list .item img {
    max-height: 250px !important;
  }
  .sec2 .list .item {
    min-height: 550px !important;
  }
}
</style>