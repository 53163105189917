<template>
  <div class="newsdetail">
    <Header />
    <div class="container-fluid heading">
      <div class="container">
        <h2 id="title"></h2>
        <div class="row align-items-center justify-content-between">
          <div class="col d-flex align-items-center">
<!--            <img src="../assets/eurst_item.png" alt="" />-->
            <p id="date"></p>
          </div>
          <div class="col d-flex align-items-center justify-content-end">
            <a href=""><i class="fa-brands fa-facebook"></i></a>
            <a href=""><i class="fa-brands fa-twitter"></i></a>
            <a href=""><i class="fa-brands fa-linkedin-in"></i></a>
            <a href=""><i class="fa-brands fa-instagram"></i></a>
            <a href=""><i class="fa-brands fa-telegram"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container py-5">
        <img id="img" src="" alt="" class="img-fluid" />
      </div>
    </div>
    <div class="container-fluid text">
      <div class="container" id="content">

      </div>
    </div>
<!--    <newsfeed />-->
    <Footer />
  </div>
</template>
  
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
// import newsfeed from "../components/newsfeed.vue";

export default {
  components: {
    Header,
    Footer,
    // newsfeed,
  },
  setup() {
    return {};
  },
};
</script>
  
<style scoped>
.heading {
  padding-top: 8rem;
}

.heading h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #1b2c42;
}

.heading .row {
  padding: 1rem 0;
}

.heading img {
  height: 1.2rem;
}

.heading p {
  font-size: 0.9rem;
  color: #616c7c;
  margin: 0;
  margin-left: 0.5em;
}

.heading a {
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
  background: #c3c8cd;
  color: white;
  text-decoration: none;
  border-radius: 50%;
  margin-left: 0.5em;
  transition: 0.5s all ease-in;
}

.heading a:hover {
  background: #2058e4;
}

.text .container {
  padding: 0 10%;
}

.text p {
  font-size: 0.9rem;
  color: #616c7c;
}

.text h4 {
  color: #1b2c42;
}

.text ul {
  padding-left: 0;
}

.text ul li {
  list-style: none;
  display: grid;
  grid-template-columns: 6ch auto;
  margin-bottom: 0.75em;
  font-size: 0.9rem;
  color: #616c7c;
}

.text ul li span {
  color: #1b2c42;
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  .heading .row {
    flex-direction: column;
  }

  .heading h2 {
    font-size: 2rem;
  }

  .heading .row {
    padding: 0 !important;
  }

  .heading .row .col.d-flex {
    justify-content: start !important;
    margin-top: 2rem;
  }

  .heading .row .col.d-flex a {
    margin: 0 0.5em 0 0;
  }

  .text .container {
    padding: 1rem;
  }
}

.h3 strong, h3 strong {
  color: #000 !important;
}
</style>