<template>
  <div class="terms">
    <Header />
    <div class="container-fluid">
      <div class="container">
        <div class="heading">
          <h4>Disclaimer</h4>
          <h2>EURST Terms of Use</h2>
          <h6>
            <img src="../assets/images/calender.png" alt="" /> Last Updated : June 13,
            2021
          </h6>
        </div>
        <p class="mb-5">
          Welcome to www.eurst.io (the “Website” or “Site”). Please read these
          Terms of Use our Privacy Policy (collectively the “Terms”) carefully,
          by continuing to access, link to, or use this site, or any service on
          this site, you signify your acceptance of the Term, as described
          further below.
        </p>
        <h3 class="mb-3">Acceptance of the Terms of Use</h3>
        <p>
          These terms of use are entered into by and between you and
          WallexTrust, (“Company,” “WallexTrust,” “we,” “our,” or “us”), and
          they govern your access to and use of the Website, including any
          content, functionality, and services offered on or through the
          Website, unless a different policy is provided on a particular site,
          application or service, in which case such different policy shall
          govern and control. <br /><br />
          Please read the Terms carefully before you use the Website. By using
          the Website, or by clicking to accept or agree to the Terms of Use
          when this option is made available to you, you accept and agree to be
          bound and abide by these Terms of Use, our Privacy Policy. If you do
          not agree to these Terms of Use and Privacy Policy do not access or
          use the Website or any services.
        </p>
        <h3 class="mt-5 mb-3">Eligibility</h3>
        <p>
          The Website is only intended for adults eighteen (18) years of age and
          older. You are not allowed to use the Website, or provide information
          on it if you are under eighteen (18) years of age. You further
          represent that you are not a citizen, resident, or member of any
          jurisdiction or group that is subject to economic sanctions by the
          United States, or where your use of the Website would be illegal or
          otherwise violate any applicable law. You further represent that your
          access and use of the Website will fully comply with all applicable
          laws and regulations, and that you will not access or use the Website
          to conduct, promote, or otherwise facilitate any illegal activity.
        </p>
        <h3 class="mt-5 mb-3">Changes to the Terms of Use and Website</h3>
        <p>
          We will likely change and update these Terms from time to time, and
          these changes are effective as soon as we post them on the Website. We
          reserve the right, in our sole discretion, to modify this Agreement
          from time to time. If you continue to use the Website following the
          posting of any revised Terms, you are accepting and agreeing to the
          changes. <br /><br />
          We may also update the content on the Website as we see fit, but do
          not guarantee that any content is necessarily complete or up-to-date.
        </p>
        <h3 class="mt-5 mb-3">No Class Proceedings</h3>
        <p>
          You and we agree that any party hereto may bring claims against the
          other on an individual basis only and not as a plaintiff or class
          member in any purported class or representative action or proceeding.
          Unless the parties agree otherwise, any adjudicator of any
          claim—whether judicial or otherwise—may not consolidate or join more
          than one party’s claims and may not otherwise preside over any form of
          a consolidated, representative, or class proceeding. Any adjudicator
          of any claim may award relief, including monetary, injunctive, and
          declaratory relief, only in favor of the party seeking relief, and
          only to the extent necessary to provide relief necessitated by that
          party’s claim(s). Any relief awarded cannot affect other users of the
          Website or of any Services.
        </p>
        <h3 class="mt-5 mb-3">Taxes</h3>
        <p>
          You are solely liable for all the taxes, fees, and commissions
          resulting from the use of the Website. These Terms of Use alone do not
          create a joint venture, partnership, or principal-agent relationship
          between you and the Company, or any other users, and nothing in these
          Terms of Use may be used to imply such a relationship. You agree to
          indemnify the Company from any liability for, or assessment of, any
          claims or penalties with respect to such taxes, labor, or employment
          requirements, including any liability for, or assessment of taxes
          imposed on the Company by the relevant taxing authorities with respect
          to any fees paid to you as the result of using the Website.
        </p>
        <h3 class="mt-5 mb-3">Assumption of Risk; No Advice</h3>
        <p>
          Cryptocurrency markets are volatile and shift quickly in terms of
          liquidity, market depth, and trading dynamics. You are solely
          responsible and liable for knowing the true status of your digital
          assets. <br /><br />
          The Company does not provide investment advice. The information
          contained within the Website is for informational purposes only, and
          the Company is not soliciting any action based upon such materials.
          The material is not to be construed as investment advice. We do not
          provide any consultation on the advisability of lending or borrowing
          digital assets, trading techniques, models, algorithms, or any other
          schemes. <br /><br />
          To the fullest extent permitted by law, you acknowledge and agree that
          we owe no fiduciary duties or liabilities to you or any other party,
          and that to the extent any such duties or liabilities may exist at law
          or in equity, those duties and liabilities are hereby irrevocably
          disclaimed, waived, and eliminated. You further agree that the only
          duties and obligations that we owe you are those set out expressly in
          these Terms.
        </p>
        <h3 class="mt-5 mb-3">Assumption of Risk</h3>
        <p>
          By accessing and using the Website, you represent that you understand
          the inherent risks associated with using cryptographic and
          blockchain-based systems, and that you have a working knowledge of the
          usage and intricacies of digital assets such as bitcoin (BTC), ether
          (ETH), and other digital tokens such as those following the Ethereum
          Token Standard (ERC-20). You further understand that the markets for
          these digital assets are highly volatile due to factors including (but
          not limited to) adoption, speculation, technology, security, and
          regulation. You acknowledge that the cost and speed of transacting
          with cryptographic and blockchain-based systems such as Ethereum are
          variable and may increase dramatically at any time. You further
          acknowledge the risk that your digital assets may lose some or all of
          their value while they are lent through the Protocol. You further
          acknowledge that we are not responsible for any of these variables or
          risks, and cannot be held liable for any resulting losses that you
          experience while accessing or using the Website. Accordingly, you
          understand and agree to assume full responsibility for all of the
          risks of accessing and using the Website and interacting with the
          Protocol.
        </p>
        <h3 class="mt-5 mb-3">Feedback</h3>
        <p>
          In the event that you provide us any ideas, thoughts, criticisms,
          suggested improvements, or other feedback related to Website
          (collectively “Feedback”), you agree that we may use the Feedback in
          any way we deem appropriate to:
        </p>
        <ul>
          <li>improve our Website</li>
          <li>
            promote the Website, and that you will not be due any compensation
            for your Feedback that is used in these ways.
          </li>
        </ul>
        <h3 class="mt-5 mb-3">Intellectual Property Rights</h3>
        <p>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection, and
          arrangement thereof), are owned by the Company, its licensors, or
          other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws. <br /><br />
          These Terms of Use permit you to use the Website for your use only,
          subject to the following restrictions:
        </p>
        <h3 class="mt-5 mb-3">Intellectual Property Rights</h3>
        <p>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection, and
          arrangement thereof), are owned by the Company, its licensors, or
          other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws. <br /><br />
          These Terms of Use permit you to use the Website for your use only,
          subject to the following restrictions:
        </p>
        <ul>
          <li>
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the material on the Website, except as it is
            created and owned by you.
          </li>
          <li>
            You must not delete or alter any copyright, trademark, or other
            proprietary rights notices from copies of materials from this site.
          </li>
          <li>
            No right, title, or interest in or to the Website or any content on
            the Website is transferred to you, and all rights not expressly
            granted are reserved by the Company.
          </li>
        </ul>
        <h3 class="mt-5 mb-3">Prohibited Uses</h3>
        <p>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity.
          </li>
          <li>
            In any way that infringes upon the rights of others, or in any way
            is illegal, threatening, fraudulent, or harmful, or in connection
            with any unlawful, illegal, fraudulent, or harmful purpose or
            activity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the Website, or which, as determined by us, may
            harm or offend the Company or users of the Website or expose them to
            liability.
          </li>
          <li>
            By means of any robot, bot spider, or other automatic device or
            process, or by any manual process, intended to monitor or copy any
            of material on the Website or for any other unauthorized purpose.
          </li>
          <li>
            In order to disguise the proceeds of, or to further, any breach of
            applicable laws or regulations, or to deal in any contraband digital
            assets, funds, or proceeds.
          </li>
          <li>
            To use any funds or other value on the Website, or use any Services,
            with anything other than funds, keys, or digital assets that have
            been legally obtained by you and that belong to you.
          </li>
          <li>
            To interfere with or subvert our rights or obligations or the rights
            or obligations of any other Website customer or any other third
            party
          </li>
          <li>
            To engage in conduct that is detrimental to us or to any other
            Website customer or any other third party
          </li>
          <li>
            To falsify or materially omit any information or provide misleading
            information requested by us in the course of, directly or indirectly
            relating to, or arising from your activities on the Website or the
            use of any Services, including at registration
          </li>
          <li>
            To reverse-engineer, decompile, or disassemble any software running
            on the Website; or, attempt to harm us through your access to the
            Website or any Services, except that nothing in this subparagraph
            shall be construed as limiting your free speech rights under
            applicable law.
          </li>
          <li>
            In order to disguise the proceeds of, or to further, any breach of
            applicable laws or regulations, or to deal in any contraband digital
            assets, funds, or proceeds.
          </li>
        </ul>
        <h3 class="mt-5 mb-3">Reliance on Information Posted</h3>
        <p>
          We do not warrant the accuracy, completeness, or usefulness of any
          information on the Website. Any reliance you place on such information
          is strictly at your own risk. We disclaim all liability and
          responsibility arising from any reliance placed on such materials by
          you or any other visitor to the Website, or by anyone who may be
          informed of any of its contents. <br /><br />
          The Website includes content provided by third parties, including
          materials provided by other users, attorneys, third-party licensors,
          syndicators, aggregators, and/or reporting services. All statements
          and/or opinions expressed in these materials, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials. We
          are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <h3 class="mt-5 mb-3">Links from the Website</h3>
        <p>
          Links to any third-party websites on the Website are provided for your
          convenience only. We have no control over the contents of those sites
          or resources and such links are not an endorsement of any information,
          product or service that is offered on or reached through such
          third-party websites. If you decide to access any of the third-party
          websites linked to the Website, you do so entirely at your own risk
          and subject to the terms and conditions of use for such websites.
        </p>
        <h3 class="mt-5 mb-3">Users Outside the U.S.</h3>
        <p>
          The Website is controlled and operated from the United States and is
          subject to its laws. If you choose to access the Website outside of
          the United States, you do so at your own risk and are responsible for
          complying with all applicable laws, rules, and regulations.
        </p>
        <h3 class="mt-5 mb-3">Release of Claims</h3>
        <p>
          You expressly agree that you assume all risks in connection with your
          access and use of the Website and your interaction with the Protocol.
          You further expressly waive and release us from any and all liability,
          claims, causes of action, or damages arising from or in any way
          relating to your use of the Website and your interaction with the
          Protocol.
        </p>
        <h3 class="mt-5 mb-3">Disclaimer of Warranties</h3>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. <br /><br />
          NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
          ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
          SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
          WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
          ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
          WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
          DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES
          IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
          THE WEBSITE, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. <br /><br />
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
          FOR PARTICULAR PURPOSE.THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
          WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. <br /><br />
        </p>
        <h3 class="mt-5 mb-3">Indemnification</h3>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) (collectively
          “Claims”) arising out of or relating to:
        </p>
        <ul>
          <li>your violation of these Terms of Use</li>
          <li>your use of the Website</li>
        </ul>
        <h3 class="mt-5 mb-3">Governing Law</h3>
        <p>
          All matters relating to the Website and these Terms and any dispute or
          claim arising therefrom or related thereto shall be governed by the
          internal laws of the District of Columbia without giving effect to any
          choice or conflict of law provisions or rules.
        </p>
        <h3 class="mt-5 mb-3">Arbitration</h3>
        <p>
          At the Company’s sole discretion, it may require you to submit any
          disputes arising from the use of these Terms of Use or the Website,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance, or termination, to final and
          binding arbitration under the Rules of Arbitration of the American
          Arbitration Association applying District of Columbia law.
        </p>
        <h3 class="mt-5 mb-3">Waiver and Severability</h3>
        <p>
          No waiver by the Company of any term or condition set forth in these
          Terms shall be deemed a further or continuing waiver of such term or
          condition, and any failure of the Company to assert a right or
          provision under these Terms shall not constitute a waiver of such
          right or provision. <br /><br />
          If any provision of these Terms is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent such that the remaining provisions of the Terms will
          continue in full force and effect.
        </p>
        <h3 class="mt-5 mb-3">Entire Agreement</h3>
        <p>
          The Terms of Use and our Privacy Policy constitute the sole and entire
          agreement between you and the Company. with respect to the Website and
          supersedes all prior and contemporaneous understandings, agreements,
          representations and warranties, both written and oral, with respect to
          the Website.
        </p>
        <p>
          Your Comments and Concerns <br /><br />
          All feedback, comments, requests for technical support and other
          communications relating to the Website should be directed to:
          <a href="">support@eurst.io</a>.
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.container {
  padding-block: 8rem 3rem;
}

.heading {
  padding: 3rem 0 2.5rem;
}

.heading h4 {
  color: #2058e4;
  font-size: 1.2rem;
}

.heading h2 {
  color: #1b2c42;
  font-size: 2.5rem;
  font-weight: bold;
}

.heading h6 {
  color: #616c7c;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.heading h6 img {
  height: 14px;
  margin-right: 5px;
}

p {
  color: #616c7c;
  font-size: 0.9rem;
}

h3 {
  color: #1b2c42;
  font-size: 1.75rem;
  font-weight: 600;
}

ul{
  margin-top: 1.5em;
}

ul li {
  color: #616c7c;
  font-size: 16px;
  margin-bottom: 0.5em;
  display: flex;
  align-items: flex-start;
  font-family: Inter;
  font-weight: 500;
}

ul li::before {
  content: '';
  display: block;
  background-color: #2058e4;
  height: 10px !important;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 1em;
  margin-top: 0.35em;
}
</style>